.movie-card {
    margin-left: 10px;
    margin-bottom: 10px;
    max-width: 300px;
    width: 300px;
}
.card-deck{
    text-align: center;
}

.movie-card{
    display: inline-block;
}

.movie-card.card {
    height: 540px;
    cursor: pointer;
    vertical-align:top;
}

.movie-card.card2 {
    height: 450px;
    cursor: pointer;
    vertical-align:top;
    position: relative;

    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-weight: 400;
    box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
    border-radius: 8px;
    background-color: white;
    background-color: white;
    box-sizing: border-box;
    display: block;
    margin: 8px;
    padding: 16px;
    text-align: left;
    word-wrap: break-word;
}

.card-footer-badge {
    font-size: 1rem;
}
.text-justify{
    text-overflow: ellipsis;
   display: -webkit-box;
//    -webkit-line-clamp: 3; /* number of lines to show */
//            line-clamp: 3; 
//    -webkit-box-orient: vertical;
}