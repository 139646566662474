body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./asset/images/8071.jpg'); */
  overflow:  unset !important;
}

.block-ui{
  position: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 .act{
   background-color: #cccccc !important;
 }

 .sk-hide{
   display: none !important;
 }



 #myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
#myVideo2 {
width: 100%;
}
.iframe-2{
  width: 100% !important;
  height: 500px !important;
}


/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

.dtw{
  background-color: #ef6184;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50px;
  position: relative;
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70%;
}